import { ComponentType, lazy } from "react";
import { translation as t } from "./data/locale";
const Home = lazy(() => import("./pages/Home"));
const Jobs = lazy(() => import("./pages/Jobs"));
const Reports = lazy(() => import("./pages/Reports"));
const GovFiles = lazy(() => import("./pages/GovFiles"));
const Donations = lazy(() => import("./pages/Donations"));
const Initiatives = lazy(() => import("./pages/InitiativesProjects"));
const Committees = lazy(() => import("./pages/Committees"));
const Membership = lazy(() => import("./pages/Membership"));
const Beneficiary = lazy(() => import("./pages/Beneficiary"));
const Volunteer = lazy(() => import("./pages/Volunteer"));
const Structure = lazy(() => import("./pages/Structure"));
const GeneralAssembly = lazy(() => import("./pages/GeneralAssembly"));
const Founding = lazy(() => import("./pages/Founding"));
const About = lazy(() => import("./pages/About"));
const Strategy = lazy(() => import("./pages/Strategy"));
const Contact = lazy(() => import("./pages/Contact"));
const Payment = lazy(() => import("./pages/PaymentPage"));
const PaymentStatus = lazy(() => import("./pages/PaymentStatus"));
const Dontation = lazy(() => import("./pages/Donation"));

type Route = {
  path: string;
  name: string;
  Component: ComponentType;
  exclude?: boolean;
  exact?: boolean;
  children?: Route[];
};
const routes: Route[] = [
  {
    path: "/",
    name: t.home,
    Component: Home,
    exact: true,
  },
  {
    path: "/committees",
    name: t.committees,
    Component: Committees,
  },
  {
    path: "/payment/:ndc",
    name: t.payment,
    Component: Payment,
    exclude: true,
  },
  {
    path: "/payment-status/:invoice_id",
    name: t.paymentStatus,
    Component: PaymentStatus,
    exclude: true,
  },
  {
    path: "/initiatives&projects",
    name: t["initiatives&projects"],
    Component: Initiatives,
  },
  {
    path: "/donations",
    name: t.donationCases,
    Component: Donations,
  },
  {
    path: "/reports",
    name: t.reports,
    Component: Reports,
  },
  {
    path: "/governance",
    name: t.governance,
    Component: GovFiles,
  },
  {
    path: "/e-services",
    name: t.eServices,
    Component: () => null,
    children: [
      {
        path: "/e-services/membership",
        name: t.registerMembership,
        Component: Membership,
      },
      {
        path: "/e-services/beneficiary",
        name: t.registerBene,
        Component: Beneficiary,
      },
      {
        path: "/e-services/volunteer",
        name: t.volunteer,
        Component: Volunteer,
      },
    ],
  },
  {
    path: "/jobs",
    name: t.jobs,
    Component: Jobs,
  },
  {
    path: "/about",
    name: t.aboutUs,
    Component: () => null,
    children: [
      { path: "/about", name: t.chairmanTalk, Component: About, exact: true },
      { path: "/about/strategy", name: t.strategy, Component: Strategy },
      {
        path: "/about/general-assembly",
        name: t.general_assembly,
        Component: GeneralAssembly,
      },
      { path: "/about/structure", name: t.structure, Component: Structure },
      {
        path: "/about/founding",
        name: t.foundingDecision,
        Component: Founding,
      },
    ],
  },
  {
    path: "/donation",
    name: t.donation,
    Component: Dontation,
  },
  {
    path: "/contact-us",
    name: t.contactUs,
    Component: Contact,
  },
];

export default routes;
