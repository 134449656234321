import { FC, useEffect, useRef } from "react";
import {
  IconButton,
  Button,
  ButtonProps,
  Box,
  BoxProps,
  Image,
  HStack,
  useDisclosure,
  SlideFade,
  VStack,
  Link,
  useMediaQuery,
  theme,
  useBoolean,
  Container,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { NavLink, Link as RouterLink, useLocation } from "react-router-dom";
import { GlobeIcon } from "./Icons";
import routes from "../routes";
import { locale } from "../data/locale";
// images
import logo from "../images/logo.png";
import { ChevronDownIcon } from "@chakra-ui/icons";
import StoreButton from "./buttons/StoreButton";

const { breakpoints } = theme;

type Props = BoxProps & { expand?: "md" | "lg" | "xl" };
const Header: FC<Props> = ({ expand = "lg", h = "75px", ...rest }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isExpanded] = useMediaQuery(`(min-width: ${breakpoints[expand]})`);

  useEffect(() => {
    if (isExpanded) onClose();
  }, [isExpanded]); // eslint-disable-line

  const headerRef = useRef<HTMLDivElement>(null);
  let [sticky, setSticky] = useBoolean(false);

  useEffect(() => {
    document.body.style.paddingTop = h as any; // add padding to body to act like header placeholder
    const { current } = headerRef;

    function listener() {
      if (current && window.scrollY > current.offsetTop) setSticky.on();
      else setSticky.off();
    }

    listener();
    window.addEventListener("scroll", listener);
    // cleanup
    return () => {
      document.body.style.paddingTop = "0";
      window.removeEventListener("scroll", listener);
    };
  }, []); // eslint-disable-line

  const { pathname } = useLocation();
  sticky = sticky || pathname !== "/"; // if not in homepage, always set sticky to true

  return (
    <Box
      as="header"
      ref={headerRef}
      pos={sticky ? "fixed" : "absolute"}
      top="0"
      insetStart="0"
      zIndex="20"
      w="100%"
      bgColor={sticky ? "#363636" : "transparent"}
      {...rest}
    >
      <Container maxW="100em">
        <HStack
          justify="space-between"
          align="center"
          h={h}
          px="4"
          color="white"
        >
          <HStack w={{ base: "full" }} spacing={{ base: "4", [expand]: "8" }}>
            <HamburgerButton
              isActive={isOpen}
              onClick={onToggle}
              colorScheme="white"
              d={{ [expand]: "none" }}
            />
            <Link as={RouterLink} to="/" ms={{ [expand]: "0 !important" }}>
              <Image
                src={logo}
                alt="Baity"
                loading="eager"
                htmlWidth="157"
                htmlHeight="75"
                w="7rem"
                h="auto"
              />
            </Link>
            <HStack spacing="5" d={{ base: "none", [expand]: "flex" }}>
              {routes.map(({ name, path, children, exact, exclude }, i) => {
                if (exclude) return null;
                if (children) {
                  return (
                    <Menu key={i}>
                      <MenuButton as={Link}>
                        {name}
                        <ChevronDownIcon />
                      </MenuButton>
                      <MenuList color="initial">
                        {children.map(({ name, path }, i) => (
                          <MenuItem key={i} as={RouterLink} to={path}>
                            {name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  );
                }
                return (
                  <Link key={i} as={NavLink} to={path} exact={exact}>
                    {name}
                  </Link>
                );
              })}
            </HStack>
          </HStack>
          <StoreButton />
          <Button
            variant="link"
            iconSpacing="1.5"
            colorScheme="whiteAlpha"
            d="none"
            leftIcon={
              <GlobeIcon
                boxSize="8"
                p="2"
                borderRadius="full"
                color="white"
                bgColor="rgba(255, 255, 255, 0.15)"
              />
            }
            onClick={() => {
              localStorage.setItem("locale", locale === "ar" ? "en" : "ar");
              window.location.reload();
            }}
          >
            {locale === "ar" ? "English" : "عربي"}
          </Button>
        </HStack>
      </Container>
      <SlideFade in={isOpen} offsetY="20px" unmountOnExit>
        <VStack
          px="4"
          py="2"
          pos="absolute"
          left="3"
          right="3"
          maxH="70vh"
          overflowY="auto"
          bgColor="white"
        >
          {routes.map(({ name, path, children, exact }, i) => {
            if (children) {
              return (
                <Menu key={i}>
                  <MenuButton as={Link} width="full">
                    {name}
                    <ChevronDownIcon />
                  </MenuButton>
                  <MenuList color="initial">
                    {children.map(({ name, path }, i) => (
                      <MenuItem key={i} as={RouterLink} to={path}>
                        {name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              );
            }
            return (
              <Link
                key={i}
                as={NavLink}
                to={path}
                exact={exact}
                p="1.5"
                w="100%"
              >
                {name}
              </Link>
            );
          })}
        </VStack>
      </SlideFade>
    </Box>
  );
};

const HamburgerButton: FC<ButtonProps> = ({ isActive, ...rest }) => {
  return (
    <IconButton
      aria-label="menu"
      variant="ghost"
      {...rest}
      icon={
        <Box
          as="span"
          role="group"
          data-active={isActive || undefined}
          aria-hidden="true"
          d="block"
          mx="auto"
          w="6"
          h="6"
          pos="relative"
          pointerEvents="none"
          sx={{
            span: {
              d: "inline-block",
              pos: "absolute",
              left: "0.125rem",
              w: "1.25rem",
              h: "0.125rem",
              borderRadius: "full",
              background: "currentColor",
              transition: "all 0.12s ease 0s",
            },
          }}
        >
          <Box
            as="span"
            top="0.4375rem"
            _groupActive={{ top: "0.6875rem", transform: "rotate(45deg)" }}
          />
          <Box
            as="span"
            bottom="0.4375rem"
            _groupActive={{ bottom: "0.6875rem", transform: "rotate(-45deg)" }}
          />
        </Box>
      }
    />
  );
};

export default Header;
